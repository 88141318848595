export interface ReportTableColumn<T> {
  field: string;
  label?: string;
  altText?: string;
  narrowFilter?: boolean;
  dropdownFilter?: boolean;
  dropdownMultipleFilter?: boolean;
  click?: (data: T) => void;
  date?: boolean;
  disabled?: (data: T) => boolean;
  hideFilter?: boolean;
  numeric?: boolean;
  id?: number;
  isHtml?: boolean;
  isCheckbox?: boolean;
  isHidden?: boolean;
  isExported?: boolean;
  isIcon?: boolean;
  isSectionIcon?: boolean;
  isAttachments?: boolean;
}

export type ReportTableFilterItem = Record<string, ReportTableFilter>;

export interface ReportTableFilter {
  value: string | string[];
  comparison: ReportTableComparison;
}

export interface ReportTableState {
  filters?: string;
  sort?: string;
}

export interface ReportTableFilterExtended {
  field: string;
  value: string;
  comparison: ReportTableComparison;
}

export enum ReportTableComparison {
  DEFAULT = "default",
  MATCHES = "matches",
  GREATER = "greater",
  LESS = "less"
}

export interface ReportTableSort {
  direction: string;
  field: string;
}
