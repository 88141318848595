export interface Config {
  toolbar: string;
  editorResizeMode: string;
  showFloatTextToolBar: boolean;
  showFloatLinkToolBar: boolean;
  showFloatImageToolBbar: boolean;
  showFloatTableToolBar: boolean;
  showFloatParagraph: boolean;
  showPlusButton: boolean;
  showTagList: boolean;
  showStatistics: boolean;
  toggleBorder: boolean;
  focusOnLoad: boolean;
  urlType: string;
  contentCssUrl: string;
}
export interface QuestionConfig extends Config {
  toolbarfactory_Interaction?: (
    cmd: string,
    suffix: null | unknown
  ) => HTMLElement;
  toolbar_questionToolbar?: string;
  toolbar_mobile?: string;
}
export interface HintConfig extends Config {
  toolbar_hintToolbar?: string;
  toolbar_mobile?: string;
}
export interface AnswerConfig extends Config {
  toolbar_answerToolbar?: string;
  toolbar_mobile?: string;
}
export interface EditorConfig extends Config {
  toolbar_editorToolbar?: string;
  toolbar_mobile?: string;
}
export interface Editor {
  CONFIG: {
    general: Config;
  };
  STYLES: {
    body: string;
    tables: string;
    interaction: string;
  };
  TOOLBARS: {
    html: string;
    question: string;
    hint: string;
    answer: string;
  };
}

export const EDITOR: Editor = {
  CONFIG: {
    general: {
      toolbar: "full",
      editorResizeMode: "none",
      showFloatTextToolBar: false,
      showFloatLinkToolBar: false,
      showFloatImageToolBbar: false,
      showFloatTableToolBar: false,
      showFloatParagraph: false,
      showPlusButton: false,
      showTagList: false,
      showStatistics: false,
      toggleBorder: false,
      focusOnLoad: true,
      urlType: "absolute",
      contentCssUrl: ""
    }
  },
  STYLES: {
    body: `
    <style>
      body{font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;}
    </style>
    `,
    tables: `
    <style>
      table{border-collapse: collapse;border-spacing: 0;}
      td {border-width: 1px 0 1px 1px; border-color: #dddddd; border-style: solid; padding: 0.25rem;} td:last-child {border-right: 1px solid #dddddd;}
    </style>
    `,
    interaction: `
    <style>
    span.fieldset{display:block;padding-inline:8px;padding-block:4px;border:1px solid #4C4C4C;color:#333333;width:fit-content;border-radius:2px;margin:8px 0;}
    </style>
    `
  },
  TOOLBARS: {
    html:
      "{bold,italic,underline}|{insertorderedlist,insertunorderedlist}|{superscript,subscript}|{insertlink,unlink,insertblockquote,insertchars,inserttable,insertimage,insertvideo,insertcode}|{paragraphs:dropdown}" +
      "#{cut,copy,paste,undo,redo,code}",
    question:
      "{bold,italic,underline}|{insertorderedlist,insertunorderedlist}|{superscript,subscript}|{insertlink,unlink,insertchars,inserttable,insertimage,insertcode}" +
      "#{cut,copy,paste,code,undo,redo}",
    hint:
      "{bold,italic,underline}|{superscript,subscript}|{insertchars,inserttable,insertimage,insertcode}" +
      "#{cut,copy,paste,code,undo,redo}",
    answer:
      "{bold,italic,underline}|{superscript,subscript}|{insertchars,inserttable,insertimage,insertcode}" +
      "#{cut,copy,paste,code,undo,redo}"
  }
};

export interface Interactions {
  HTML: (id: string, index: number) => string;
  DISPLAY_HTML: (id: string, index: number) => string;
  TYPES: {
    names: {
      single: string;
      multiple: string;
      openAnswer: string;
    };
    items: {
      value: () => string;
      title: string;
    }[];
    isChoiceType: (type: string) => boolean;
    isOpenAnswer: (type: string) => boolean;
  };
  MIN_CHOICES_NUMBER: number;
}
export const INTERACTIONS: Interactions = {
  HTML(id: string, index: number) {
    return `&nbsp;<span class="fieldset" id="${id}">i.${index}</span>&nbsp;`;
  },
  DISPLAY_HTML(id: string, index: number) {
    return `&nbsp;<span class="fieldset" id="${id}">Interaction ${index}</span>&nbsp;`;
  },
  TYPES: {
    names: {
      single: "mcq-single-answer",
      multiple: "mcq-multiple-answer",
      openAnswer: "open-answer"
    },
    items: [
      {
        value() {
          return "";
        },
        title: "Not selected"
      },
      {
        value() {
          return INTERACTIONS.TYPES.names.single;
        },
        title: "Single answer"
      },
      {
        value() {
          return INTERACTIONS.TYPES.names.multiple;
        },
        title: "Multiple answers"
      },
      {
        value() {
          return INTERACTIONS.TYPES.names.openAnswer;
        },
        title: "Open answer"
      }
    ],
    isChoiceType(type: string) {
      return [
        INTERACTIONS.TYPES.names.single,
        INTERACTIONS.TYPES.names.multiple
      ].includes(type);
    },
    isOpenAnswer(type: string) {
      return type === INTERACTIONS.TYPES.names.openAnswer;
    }
  },
  MIN_CHOICES_NUMBER: 2
};
