<template>
  <RightSidebar
    v-if="section"
    @closeSidebar="closeSidebar"
    :data-testing="'section-sidebar'"
    :close-btn-data-testing="'close-right-sidebar'"
    :is-updating="isUpdating"
    :is-close-btn-visible="isCloseButtonVisible"
    :css-classes="[
      'content-sidebar',
      {
        closeBtnVisible: isCloseButtonVisible,
        externalContentOpened: uiContentStore.fileLocationForPreview,
        expanded: uiContentStore.isContentSidebarExpanded
      }
    ]"
  >
    <template v-slot:additional-content>
      <ContentPreview
        v-if="uiContentStore.fileLocationForPreview"
        :section="section"
        :path="path[1]"
        @closePreview="uiContentStore.setFileLocationToPreview(undefined)"
      />
    </template>
    <template v-slot:expand-btn>
      <button
        v-if="!uiContentStore.fileLocationForPreview"
        class="expand-btn btn-1"
        type="button"
        @click="uiContentStore.toggleContentSidebarWidth"
      >
        <SVGIcon
          v-if="!uiContentStore.isContentSidebarExpanded"
          :path="mdiChevronLeft"
          :size="18"
          type="is-white"
        />
        <SVGIcon v-else :path="mdiChevronRight" :size="18" type="is-white" />
      </button>
    </template>
    <template v-slot:title>
      <ContentName
        :content-name="section.name"
        :product-id="uiContentStore.sectionToDisplay.productId"
        :section-id="uiContentStore.sectionToDisplay.sectionId"
        :can-edit-content="canEditContent"
      />
    </template>
    <template v-slot:dropdown>
      <b-dropdown
        class="is-block sidebar-dropdown-menu"
        aria-role="menu"
        position="is-bottom-left"
        :hoverable="true"
        :mobile-modal="false"
        v-if="usersStore.canUserManageWorkflows && canEditContent"
      >
        <template v-slot:trigger>
          <button class="btn-3 right-icon">
            <SVGIcon :path="mdiDotsHorizontal" type="is-white" :size="32" />
          </button>
        </template>
        <div>
          <b-dropdown-item
            aria-role="menu-item"
            :focusable="false"
            :custom="true"
            v-if="isDeployButtonShown"
          >
            <button @click="deployToQA" class="btn-unstyled btn-3 full-width">
              <SVGIcon :path="mdiPackageUp" />
              Deploy to QA
            </button>
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="menu-item"
            :focusable="false"
            :custom="true"
          >
            <button
              class="btn-unstyled btn-3 full-width"
              type="button"
              data-testing="state-delete-btn"
              @click="confirmRemoval"
            >
              <SVGIcon :path="mdiDelete" />
              Delete Content
            </button>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </template>
    <template v-slot:body>
      <div class="columns is-multiline">
        <div :class="['content-item-wrapper', 'column', 'is-4']">
          <!-- AUTHORING TOOL -->
          <div :class="['content-item', 'padding-b-standard']">
            <AuthoringTool
              :section="section"
              :product="product"
              :is-a-content-object="isAContentObject"
              :XAPIErrors="XAPIAuthoringToolErrors"
              :XAPIWarnings="XAPIQuestionsWarnings"
              @previewAttachment="previewAttachment"
            />
          </div>

          <!-- IDENTIFIERS -->
          <div :class="['content-item', 'padding-b-standard']">
            <ContentIdentifiers
              :internal-id="section.id"
              :external-id="
                section.client_external_id ? section.client_external_id : ''
              "
              :sections="product.sections"
              :can-edit-content="canEditContent"
              :product-id="uiContentStore.sectionToDisplay.productId"
            />
          </div>

          <!-- LOCATION -->
          <div :class="['content-item', 'padding-b-standard']">
            <ContentLocation
              :product="product"
              :path="path"
              :section-name="section.name"
              :section-id="section.id"
              :section-icon-id="section.type.icon_id"
              :section-external-id="sectionExternalId"
              :can-edit-content="canEditContent"
            />
          </div>

          <!-- DESCRIPTION -->
          <div
            v-if="showContentDescription"
            :class="['content-item', 'padding-b-standard']"
          >
            <ContentDescription
              :product-id="uiContentStore.sectionToDisplay.productId"
              :section-id="uiContentStore.sectionToDisplay.sectionId"
              :section="section"
              :can-edit-content="canEditContent"
            />
          </div>
        </div>

        <div :class="['content-item-wrapper', 'column', 'is-4']">
          <!-- METADATA -->
          <div
            v-if="isMetadataVisible"
            :class="['content-item', 'padding-b-standard']"
          >
            <ContentMetadata
              :is-updating="isUpdating"
              :product="product"
              :section="section"
              :displayed-metadata="displayedMetadata"
              :can-edit-content="canEditContent"
              :XAPIErrors="XAPIMetadataErrors"
            />
          </div>
        </div>

        <div :class="['content-item-wrapper', 'column', 'is-4']">
          <!-- TASKS IN CONTENT -->
          <div
            v-if="canShowWorkflow"
            :class="['content-item', 'padding-b-standard']"
            style="order: 3"
          >
            <ContentWorkflow
              :product="product"
              :workflowStates="workflowStates"
              :current-state="currentState"
              :workflow-state-from-title-id="
                uiContentStore.sectionToDisplay.workflowStateId
              "
              :section="section"
              :is-updating="isUpdating"
            />
          </div>

          <!-- ACTIVITY LOG -->
          <div
            v-if="hasWorkflowStates && workflowStates.length && section"
            :class="['content-item', 'padding-b-standard']"
          >
            <ContentActivityLog
              :section="section"
              :workflowStates="workflowStates"
              :product-id="uiContentStore.sectionToDisplay.productId"
              :section-id="uiContentStore.sectionToDisplay.sectionId"
            />
          </div>

          <!-- CURRICULUM TAGS -->
          <div
            v-if="isCurriculumTagsVisible"
            :class="['content-item', 'padding-b-standard']"
          >
            <CurriculumTags
              :section="section"
              :product-curriculum-id="product.curriculum_id"
              :is-loading="isCurriculumLoading"
              :topics-from-xml="topicsFromXml"
              :concepts-from-xml="conceptsFromXml"
              :xml-parsed-successfully="xmlParsedSuccessfully"
              :can-edit-content="canEditContent"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modals>
      <EditContentPath
        v-if="uiContentStore.isEditContentPathModalOpen"
        :path="path"
        :section-name="section.name"
        :section-id="section.id"
        :section-icon-id="section.type.icon_id"
        :product="product"
        :structure="structure"
        :section-external-id="sectionExternalId"
      />
    </template>
  </RightSidebar>
</template>
<script lang="ts" setup>
import csHelper from "@/utils/product/content-structure-helpers";
import contentAPI from "@/services/content-api";
import { WorkflowState } from "@/models/workflow.model";
import ContentName from "@/components/right-sidebars/content-sidebar/ContentName.vue";
import ContentLocation from "@/components/right-sidebars/content-sidebar/content-location/ContentLocation.vue";
import ContentDescription from "@/components/right-sidebars/content-sidebar/ContentDescription.vue";
import ContentMetadata from "@/components/right-sidebars/content-sidebar/content-metadata/ContentMetadata.vue";
import ContentWorkflow from "@/components/right-sidebars/content-sidebar/content-workflow/ContentWorkflow.vue";
import ContentActivityLog from "@/components/right-sidebars/content-sidebar/ContentActivityLog.vue";
import AuthoringTool from "@/components/right-sidebars/content-sidebar/authoring-tool/AuthoringTool.vue";
import CurriculumTags from "@/components/right-sidebars/content-sidebar/curriculum/CurriculumTags.vue";
import {
  ContentStructureSection,
  GeneratedContentStructure
} from "@/models/content-structure.model";
import { ProductItem, ProductPermission } from "@/models/product.model";
import { OrganizationUser } from "@/models/user.model";
import * as xApiHelper from "@/utils/product/x-api-helper";
import { XAPIError, XAPIErrors } from "@/models/x-api.model";
import ContentPreview from "@/components/right-sidebars/content-sidebar/ContentPreview.vue";
import { isEqual } from "lodash";
import RightSidebar from "@/components/right-sidebars/ui/RightSidebar.vue";
import ContentIdentifiers from "@/components/right-sidebars/content-sidebar/ContentIdentifiers.vue";
import EditContentPath from "@/components/popups/EditContentPath.vue";
import { AssetLocation } from "@/models/assets.model";
import { AxiosResponse } from "axios";
import { MetadataInstance } from "@/models/product-section.model";
import { getDeadlineByWorkflowState } from "@/utils/tasks-helpers";
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiDelete,
  mdiDotsHorizontal,
  mdiPackageUp
} from "@mdi/js";
import { computed, onMounted, ref, watch } from "vue";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIContentStore } from "@/stores/ui-content.store";
import { useUsersStore } from "@/stores/users.store";
import { useContentStore } from "@/stores/content.store";
import { showDialog, showToast } from "@/services/notification-manager";
import { useFeatureFlags } from "@/composables/feature-flags";
import { useCurriculumManager } from "@/composables/curriculums-manager";
import { useRoute } from "vue-router";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import { PRODUCT } from "@/constants/product.constants";
import { X_API } from "@/constants/xapi.constants";
import { TOOLS } from "@/constants/template.constants";
import { SECTION } from "@/constants/assets.constant";
import { MESSAGES } from "@/constants/messages.constants";

const route = useRoute();
const uiGeneralStore = useUIGeneralStore();
const uiContentStore = useUIContentStore();
const usersStore = useUsersStore();
const contentStore = useContentStore();
const productsStore = useProductsStore();
const contentStructureStore = useContentStructureStore();

const product = computed<ProductItem | undefined>(() => {
  if (
    productsStore.areProductDetailsLoading ||
    !uiContentStore.sectionToDisplay
  ) {
    return undefined;
  }
  return productsStore.productDetails?.find((e: ProductItem): boolean => {
    return (
      !!uiContentStore.sectionToDisplay &&
      e.id === Number(uiContentStore.sectionToDisplay.productId)
    );
  });
});
const structure = computed<GeneratedContentStructure | undefined>(() => {
  if (
    contentStructureStore.isContentStructureLoading ||
    !uiContentStore.sectionToDisplay
  ) {
    return undefined;
  }
  return contentStructureStore.contentStructure?.find(
    (e: GeneratedContentStructure): boolean => {
      return (
        !!uiContentStore.sectionToDisplay &&
        e.product_id === uiContentStore.sectionToDisplay.productId
      );
    }
  );
});
const isUpdating = computed<boolean>(() => {
  return (
    productsStore.areProductDetailsLoading ||
    contentStructureStore.isContentStructureUpdating
  );
});

const hasEditPermission = computed<boolean>(() => {
  return (
    !!product.value &&
    product.value.allocated_permissions.some(
      (e: ProductPermission): boolean => {
        return e.id === PRODUCT.PERMISSIONS.editContentStructure;
      }
    )
  );
});
const canEditContent = computed<boolean>(() => {
  return uiGeneralStore.isEditModeEnabled && hasEditPermission.value;
});

const section = computed<ContentStructureSection | undefined>(() => {
  if (structure.value?.rootSection && uiContentStore.sectionToDisplay) {
    const descendentSection: ContentStructureSection | null =
      csHelper.findDescendentSection(
        structure.value.rootSection,
        uiContentStore.sectionToDisplay.sectionId
      );
    return descendentSection ? descendentSection : undefined;
  }
  return undefined;
});
const isAContentObject = computed<boolean>(() => {
  return !!section.value && TOOLS.isContentObject(section.value.type.category);
});

const workflowStates = computed<WorkflowState[]>(() => {
  return product.value && section.value?.workflow_id
    ? csHelper.getWorkflowStatesForSection(
        product.value,
        section.value.workflow_id
      )
    : [];
});
const hasWorkflowStates = computed<boolean>(() => {
  return !!workflowStates.value.length && isAContentObject.value;
});
const canShowWorkflow = computed<boolean>(() => {
  return (
    hasWorkflowStates.value && !!workflowStates.value.length && !!product.value
  );
});
const currentState = computed<WorkflowState | undefined>(() => {
  return section.value?.workflow_id && product.value
    ? csHelper.getCurrentState(
        product.value,
        section.value.state_transitions,
        section.value.workflow_id
      )
    : undefined;
});

const sectionExternalId = computed<string>(() => {
  return !!section.value?.client_external_id
    ? section.value.client_external_id
    : "Not set";
});
const path = computed<AssetLocation[]>(() => {
  if (section.value && structure.value?.rootSection) {
    return csHelper.getPathToSectionWithIds(
      structure.value.rootSection,
      section.value.id
    );
  }
  return [];
});
const showContentDescription = computed<boolean>(() => {
  return (
    hasWorkflowStates.value &&
    (canEditContent.value || (!!section.value && !!section.value.instruction))
  );
});
const deadline = computed<number | undefined>(() => {
  if (section.value && currentState.value && product.value) {
    return getDeadlineByWorkflowState(
      section.value,
      currentState.value,
      product.value
    );
  }
  return undefined;
});
const assignedUsers = computed<OrganizationUser[]>(() => {
  if (!usersStore.users) {
    return [];
  }
  return usersStore.users.filter((userToCheck: OrganizationUser) => {
    if (currentState.value) {
      return currentState.value.users.some(
        (assignedUserId: number): boolean => {
          return assignedUserId === userToCheck.id;
        }
      );
    }
    return false;
  });
});
const displayedMetadata = computed<MetadataInstance[]>(() => {
  return section.value
    ? section.value.metadata_instances.filter((instance: MetadataInstance) => {
        return (
          (instance.metadata_definition.type === "Text Free" &&
            instance.authored_text) ||
          (instance.metadata_definition.type !== "Text Free" &&
            instance.metadata_definition.metadata_allocated_values.length > 0)
        );
      })
    : [];
});
const isMetadataVisible = computed<boolean>(() => {
  return (
    displayedMetadata.value.length > 0 ||
    (canEditContent.value &&
      !!section.value &&
      section.value.metadata_instances.length > 0)
  );
});

const {
  curriculumConceptsGuidsFromProductSections,
  curriculumJson,
  setCurriculumJson,
  resetCurriculumData,
  conceptsFromXml,
  xmlParsedSuccessfully,
  isCurriculumLoading,
  topicsFromXml
} = useCurriculumManager();
const doesProductHaveACurriculum = computed<boolean>(() => {
  return !!product.value && product.value.curriculum_id !== null;
});
const isCurriculumEnabledForSectionType = computed<boolean>(() => {
  return (
    !!section.value &&
    !section.value.isRoot &&
    section.value.type.name !== "Folder"
  );
});
const isCurriculumTagsVisible = computed<boolean>(() => {
  if (
    !!product.value &&
    doesProductHaveACurriculum.value &&
    isCurriculumEnabledForSectionType.value
  ) {
    return (
      !!curriculumConceptsGuidsFromProductSections(product.value).length ||
      (!curriculumConceptsGuidsFromProductSections(product.value).length &&
        canEditContent.value)
    );
  }
  return false;
});
onMounted(() => {
  if (!curriculumJson.value && product.value && product.value.curriculum_id) {
    setCurriculumJson(product.value.curriculum_id);
  }
});
watch(
  () => product.value?.curriculum_id,
  newVal => {
    resetCurriculumData();
    if (newVal) {
      setCurriculumJson(newVal);
    }
  }
);

const currentXAPIErrors = ref<string[]>([]);
const XAPIMetadataErrors = ref<string[]>([]);
const XAPIAuthoringToolErrors = ref<string[]>([]);
const currentXAPIWarnings = ref<string[]>([]);
const XAPIQuestionsWarnings = ref<string[]>([]);
const getMetadataErrors = (): string[] => {
  return currentXAPIErrors.value.filter((error: string): boolean => {
    return error.includes(X_API.ERRORS.metadataValueMissing);
  });
};
const getExternalLinkErrors = (): string[] => {
  return currentXAPIErrors.value.filter((error: string): boolean => {
    return (
      error.includes(X_API.ERRORS.externalLinkNotSet) ||
      error.includes(X_API.ERRORS.invalidExternalLink)
    );
  });
};
const getHTMLErrors = (): string[] => {
  return currentXAPIErrors.value.filter((error: string): boolean => {
    return error.includes(X_API.ERRORS.noHTMLDocumentAuthored);
  });
};
const getQuestionErrors = (): string[] => {
  return currentXAPIErrors.value.filter((error: string): boolean => {
    return (
      error.includes(X_API.ERRORS.noQuestionAuthored) ||
      error.includes(X_API.ERRORS.emptyQuestion) ||
      error.includes(X_API.ERRORS.noInteractions) ||
      error.includes(X_API.ERRORS.emptyChoices) ||
      error.includes(X_API.ERRORS.noCorrectAnswers) ||
      error.includes(X_API.ERRORS.lessThan2Choices) ||
      error.includes(X_API.ERRORS.interactionTypeNotSelected)
    );
  });
};
const getQTIWarnings = (): string[] => {
  return currentXAPIWarnings.value.filter((error: string): boolean => {
    return (
      error.includes(X_API.ERRORS.noQuestionsAuthored) ||
      error.includes(X_API.ERRORS.questionsContainErrors)
    );
  });
};
const getQuestionWarnings = (): string[] => {
  return currentXAPIWarnings.value.filter((error: string): boolean => {
    return (
      error.includes(X_API.ERRORS.hintNotAdded) ||
      error.includes(X_API.ERRORS.solutionNotAdded)
    );
  });
};
const collectXAPIErrors = (): void => {
  currentXAPIErrors.value = [];
  currentXAPIWarnings.value = [];
  const currentErrors = uiContentStore.XAPIErrors
    ? uiContentStore.XAPIErrors.contentErrors.find(
        (error: XAPIError): boolean => {
          return !!section.value && error.sectionId === section.value.id;
        }
      )
    : undefined;
  const currentWarnings = uiContentStore.XAPIErrors
    ? uiContentStore.XAPIErrors.contentWarnings.find(
        (error: XAPIError): boolean => {
          return !!section.value && error.sectionId === section.value.id;
        }
      )
    : undefined;
  currentXAPIErrors.value = currentErrors ? [...currentErrors.errors] : [];
  currentXAPIWarnings.value = currentWarnings
    ? [...currentWarnings.errors]
    : [];
  XAPIAuthoringToolErrors.value = [
    ...getExternalLinkErrors(),
    ...getHTMLErrors(),
    ...getQuestionErrors()
  ];
  XAPIMetadataErrors.value = getMetadataErrors();
  XAPIQuestionsWarnings.value = [...getQTIWarnings(), ...getQuestionWarnings()];
};
const checkXAPI = async (): Promise<void> => {
  if (uiContentStore.sectionToDisplay) {
    const allErrors: XAPIErrors = product.value
      ? await xApiHelper.validateBuildXAPI(product.value)
      : {
          contentErrors: [],
          contentTemplatesErrors: [],
          contentWarnings: []
        };
    uiContentStore.setXAPIErrors({
      productId: uiContentStore.sectionToDisplay.productId,
      ...allErrors
    });
    collectXAPIErrors();
  }
};
let oldVal = uiContentStore.XAPIErrors;
uiContentStore.$subscribe((mutation, state) => {
  const newVal = state.XAPIErrors;
  if (newVal) {
    if (route.name === "content" && !isEqual(newVal, oldVal)) {
      collectXAPIErrors();
      oldVal = newVal;
    }
  }
});
uiContentStore.$subscribe((mutation, state) => {
  const newVal = state.sectionToDisplay;
  if (newVal) {
    if (route.name === "content" && newVal) {
      collectXAPIErrors();
    }
  }
});
watch(
  () => product.value,
  newVal => {
    if (newVal && newVal.is_xapi && route.name !== "content") {
      checkXAPI();
    }
  }
);
onMounted(() => {
  if (product.value && product.value.is_xapi) {
    if (route.name !== "content") {
      checkXAPI();
    } else {
      collectXAPIErrors();
    }
  }
});

const removeContent = () => {
  if (section.value && uiContentStore.sectionToDisplay) {
    contentStore.deleteSection({
      productId: uiContentStore.sectionToDisplay.productId,
      sectionsId: section.value.id
    });
  }
  uiContentStore.closeContentSidebar();
};
const confirmRemoval = (): void => {
  showDialog({
    message: "Are you sure you want to continue?",
    cancelText: "No",
    confirmText: "Yes",
    onConfirm: () => {
      removeContent();
    }
  });
};

const previewAttachment = (fileLocation: string): void => {
  if (uiContentStore.isContentSidebarExpanded) {
    uiContentStore.toggleContentSidebarWidth();
  }
  uiContentStore.setFileLocationToPreview(fileLocation);
};
const closeSidebar = (): void => {
  if (uiContentStore.fileLocationForPreview) {
    uiContentStore.setFileLocationToPreview(undefined);
  }
  uiContentStore.closeContentSidebar();
};

const { hasDeployButton } = useFeatureFlags();
const hasQTIAuthoring = computed<boolean>(() => {
  return !!section.value && TOOLS.isQTITool(section.value.type.tool_id);
});
const isDeployButtonShown = computed<boolean | undefined>(() => {
  return (
    !!uiContentStore.sectionToDisplay &&
    hasDeployButton(
      hasQTIAuthoring.value,
      uiContentStore.sectionToDisplay.productId
    )
  );
});
const showDeployToQAError = () => {
  showToast({
    duration: 10000,
    message: section.value
      ? `${SECTION.ERRORS.deployingToQA} ${section.value.name}`
      : `${SECTION.ERRORS.deployingToQA}`,
    type: "is-danger"
  });
};
const deployToQA = (): void => {
  if (section.value?.authored_document_id) {
    contentAPI
      .deployToQA(section.value.authored_document_id, section.value.name)
      .then((response: AxiosResponse): void => {
        if (response.status === 200) {
          showToast({
            duration: 10000,
            message: section.value
              ? `${section.value.name} ${MESSAGES.SUCCESS.deployed}`
              : `${MESSAGES.SUCCESS.deployed}`,
            type: "is-success"
          });
        } else {
          showDeployToQAError();
        }
      })
      .catch((): void => {
        showDeployToQAError();
      });
  }
};

const isCloseButtonVisible = computed<boolean>(() => {
  return (
    !!uiContentStore.sectionToDisplay &&
    uiContentStore.sectionToDisplay.hasCloseButton
  );
});
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0;
}
li {
  list-style: none;
  padding-bottom: 0.1rem;
}
button {
  &:hover[title]::after {
    white-space: pre;
  }
}
</style>

<style lang="scss">
.expand-btn {
  position: absolute;
  top: 5.5rem;
  left: -0.8125rem;
  z-index: 10;
  border-radius: 50% !important;
  padding: 0.25rem !important;
  min-width: 0 !important;
  line-height: 1rem !important;
}
.right-sidebar.content-sidebar {
  overflow: visible !important;
  .right-sidebar-body {
    padding: 1.75rem;
  }
  .content-item-wrapper {
    display: contents;
  }
  .content-item {
    width: 100%;
  }
}
.right-sidebar.content-sidebar.externalContentOpened {
  overflow: visible;
  width: 100% !important;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 902;
  .sidebar {
    margin-left: auto;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);
  }
}
.right-sidebar.content-sidebar.expanded {
  width: 100% !important;
  pointer-events: none;
  .sidebar {
    width: 80%;
    pointer-events: visible;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);
    @media (min-width: $breakpoint-md) {
      width: 80%;
    }
  }
  .right-sidebar-body {
    .content-item-wrapper {
      display: block;
      &:empty {
        display: none;
      }
    }
  }
}
</style>
