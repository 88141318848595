import { ContentExtractionApi, DAO } from "@/api/DAO";
import { FILES_URL } from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import { AITutorFile } from "@/models/content-extraction.model";

const upload = (payload: FormData): Promise<void> => {
  return DAO().post(`${FILES_URL}/ai-tutor`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
const getAll = (
  productId: number
): Promise<AxiosResponse<{ data: AITutorFile[] }>> => {
  return DAO().get(`${FILES_URL}/ai-tutor/${productId}`);
};

const extractContent = (payload: { s3_folder_url: string }) => {
  return ContentExtractionApi().post(
    "/api/content-extraction/extract-content",
    payload
  );
};

export { upload, getAll, extractContent };
