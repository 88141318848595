import { defineStore } from "pinia";
import API from "@/api";
import { computed, ref } from "vue";
import axios, { AxiosError, AxiosResponse } from "axios";
import { OrganizationUser, UserPermission } from "@/models/user.model";
import {
  GetAllResponse,
  PasswordToChange,
  UserToAddToStateForStore,
  UserToCreate,
  UserToRemoveFromStateForStore,
  UserToUpdate
} from "@/models/api/users.model";
import { CurrentUser } from "@/models/store/user-state.model";
import {
  checkPermission,
  getCurrentUser,
  getOrganizationUsers
} from "@/utils/store-helpers";
import {
  Permission,
  PermissionPayload
} from "@/models/available-permissions.model";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import { USER } from "@/constants/user.constants";

export const useUsersStore = defineStore("users", () => {
  const productsStore = useProductsStore();
  const contStrStore = useContentStructureStore();

  const areUsersLoading = ref<boolean>(true);
  const areUsersUpdating = ref<boolean>(false);
  const users = ref<OrganizationUser[] | null>(null);

  const availableUserPermissions = ref<UserPermission[]>([]);

  const currentUser = ref<CurrentUser | null>(null);
  const canUserManageUsers = computed<boolean>(() => {
    return (
      !!currentUser.value &&
      checkPermission(
        currentUser.value.permissions,
        USER.PERMISSIONS.manageUsers
      )
    );
  });
  const canUserManageProducts = computed<boolean>(() => {
    return (
      !!currentUser.value &&
      checkPermission(
        currentUser.value.permissions,
        USER.PERMISSIONS.manageProducts
      )
    );
  });
  const canUserManageWorkflows = computed<boolean>(() => {
    return (
      !!currentUser.value &&
      checkPermission(
        currentUser.value.permissions,
        USER.PERMISSIONS.manageWorkflows
      )
    );
  });
  const canUserManageContent = computed<boolean>(() => {
    return (
      !!currentUser.value &&
      checkPermission(
        currentUser.value.permissions,
        USER.PERMISSIONS.manageContent
      )
    );
  });

  const populateUsers = (): Promise<void> => {
    return API.user.getAll().then((response: AxiosResponse<GetAllResponse>) => {
      areUsersLoading.value = false;
      areUsersUpdating.value = false;
      const data = response.data.data;
      users.value = getOrganizationUsers(data.users);
      availableUserPermissions.value = data.available_permissions.filter(
        (permission: Permission) => {
          return permission.id !== USER.PERMISSIONS.manageContent;
        }
      );
      currentUser.value = getCurrentUser(
        data.id,
        data.type,
        data.permissions,
        data.users
      );
    });
  };

  const createUser = ({
    username,
    password,
    firstName,
    lastName,
    type
  }: UserToCreate): Promise<void> => {
    areUsersUpdating.value = true;
    return API.user
      .create(username, password, firstName, lastName, type)
      .then(() => {
        return populateUsers();
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  };
  const updateUser = (payload: UserToUpdate): Promise<void> => {
    areUsersUpdating.value = true;
    return API.user.update(payload).then(() => {
      return populateUsers();
    });
  };
  const disableUser = (userId: number): Promise<void> => {
    areUsersUpdating.value = true;
    return API.user.disable(userId).then(() => {
      return populateUsers();
    });
  };
  const changePassword = (payload: PasswordToChange) => {
    return API.user.changePassword(payload);
  };

  const addUserPermission = (payload: PermissionPayload) => {
    productsStore.isProductSummaryUpdating = true;
    if (payload.product_id) {
      productsStore.areProductDetailsUpdating = true;
    }
    areUsersUpdating.value = true;
    return API.permissions.addUserPermissions(payload).then(() => {
      productsStore.populateProductSummary();
      if (payload.product_id) {
        productsStore.populateProductDetails(payload.product_id);
      }
      return populateUsers();
    });
  };
  const removeUserPermission = (payload: PermissionPayload) => {
    productsStore.isProductSummaryUpdating = true;
    if (payload.product_id) {
      productsStore.areProductDetailsUpdating = true;
    }
    areUsersUpdating.value = true;
    return API.permissions.removeUserPermissions(payload).then(() => {
      productsStore.populateProductSummary();
      if (payload.product_id) {
        productsStore.populateProductDetails(payload.product_id);
      }
      return populateUsers();
    });
  };

  const putUser = (payload: UserToAddToStateForStore) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.user.addToState(payload.user).then(() => {
      return productsStore.populateProductDetails(payload.product_id);
    });
  };
  const removeUser = (payload: UserToRemoveFromStateForStore) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.user.removeFromState(payload.user).then(() => {
      return productsStore.populateProductDetails(payload.product_id);
    });
  };

  return {
    areUsersLoading,
    areUsersUpdating,
    users,
    availableUserPermissions,
    currentUser,
    canUserManageUsers,
    canUserManageProducts,
    canUserManageWorkflows,
    canUserManageContent,
    populateUsers,
    createUser,
    updateUser,
    disableUser,
    changePassword,
    addUserPermission,
    removeUserPermission,
    putUser,
    removeUser
  };
});
