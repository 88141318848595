<template>
  <div class="sidebar" :class="{ open: uiGeneralStore.isSidebarOpen }">
    <div class="sidebar-inner" data-testing="sidebar-inner">
      <h2
        :class="{ 'padding-standard': uiGeneralStore.isSidebarOpen }"
        class="lineheight-1 sidebar-header"
      >
        <span v-if="uiGeneralStore.isSidebarOpen" class="sidebar-logo"
          >THOTH</span
        >
        <button
          @click="uiGeneralStore.toggleSidebarWidth()"
          class="btn-unstyled btn-toggle"
          data-testing="sidebar-hamburger"
        >
          <span v-if="uiGeneralStore.isSidebarOpen">
            <SVGIcon :path="mdiBackburger" :size="24" />
          </span>
          <span v-else class="icon-close">
            <SVGIcon :path="mdiMenu" :size="24" />
          </span>
        </button>
      </h2>
      <div class="primary-links">
        <SidebarLink v-for="link in links" :link="link" :key="link.title">
          <component :is="link.icon"></component>
        </SidebarLink>
      </div>
      <SidebarPrograms v-if="uiGeneralStore.isSidebarOpen" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import HomeIcon from "@/components/sidebar/icons/HomeIcon.vue";
import TasksIcon from "@/components/sidebar/icons/TasksIcon.vue";
import ProductsIcon from "@/components/sidebar/icons/ProductsIcon.vue";
import ProgrammesIcon from "@/components/sidebar/icons/ProgrammesIcon.vue";
import CurriculumIcon from "@/components/sidebar/icons/CurriculumIcon.vue";
import FilesIcon from "@/components/sidebar/icons/FilesIcon.vue";
import TeamIcon from "@/components/sidebar/icons/TeamIcon.vue";
import { SidebarNavigationLink } from "@/models/sidebar.model";
import SidebarLink from "@/components/sidebar/SidebarLink.vue";
import SidebarPrograms from "@/components/sidebar/SidebarPrograms.vue";
import { getProductsForDisplay } from "@/utils/filter-products-helpers";
import { ProductSummaryItem } from "@/models/product.model";
import { computed } from "vue";
import { mdiMenu, mdiBackburger } from "@mdi/js";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useOrganizationStore } from "@/stores/organization.store";
import { useUsersStore } from "@/stores/users.store";
import { useProductsStore } from "@/stores/products.store";
import { useRoute } from "vue-router";
import { useTasksQueryManager } from "@/composables/tasks-query-manager";

const uiGeneralStore = useUIGeneralStore();
const orgStore = useOrganizationStore();
const usersStore = useUsersStore();
const productsStore = useProductsStore();
const route = useRoute();
const { getTasksQuery } = useTasksQueryManager();

const views = {
  home: ["home"],
  tasks: ["tasks"],
  programmes: [
    "programmes",
    "products",
    "createProgramme",
    "createProductInProgramme",
    "createProduct",
    "product",
    "content",
    "content-templates",
    "assets",
    "progress",
    "productTasks",
    "planning",
    "product-permissions",
    "feature-management",
    "qti-editor",
    "qti-preview",
    "html-editor",
    "html-preview",
    "question-editor",
    "content-extractor",
    "adaptive-learning-systems-list",
    "adaptive-learning-system"
  ],
  products: ["all-products"],
  users: ["organizations", "createUser", "users"],
  files: ["files"],
  curriculums: ["curriculums", "createCurriculum", "curriculum"]
};

const productsForDisplay = computed<ProductSummaryItem[]>(() => {
  return usersStore.currentUser && productsStore.productSummary
    ? getProductsForDisplay(
        productsStore.productSummary,
        usersStore.currentUser.permissions
      )
    : [];
});
const isCurrentRouteIncluded = (namesArray: string[]) => {
  return (
    !!route.name &&
    typeof route.name === "string" &&
    namesArray.includes(route.name)
  );
};
const links = computed<SidebarNavigationLink[]>(() => {
  return [
    {
      title: "Home",
      isHighlighted: isCurrentRouteIncluded(views.home),
      to: {
        name: "home",
        query: getTasksQuery() as unknown as Record<string, string>
      },
      icon: HomeIcon
    },
    {
      title: "Tasks",
      dataTesting: "sidebar-tasks",
      isHighlighted: isCurrentRouteIncluded(views.tasks),
      isNotAvailable:
        !orgStore.canOrgManageProgrammes || usersStore.areUsersLoading,
      to: {
        name: "tasks",
        query: getTasksQuery() as unknown as Record<string, string>
      },
      icon: TasksIcon
    },
    {
      title: "Products",
      dataTesting: "sidebar-products",
      isHighlighted: isCurrentRouteIncluded(views.products),
      isNotAvailable: productsForDisplay.value.length === 0,
      to: {
        name: "all-products"
      },
      icon: ProductsIcon
    },
    {
      title: "Programmes",
      dataTesting: "sidebar-programmes",
      isHighlighted: isCurrentRouteIncluded(views.programmes),
      isNotAvailable: !orgStore.canOrgManageProgrammes,
      to: {
        name: "programmes"
      },
      icon: ProgrammesIcon
    },
    {
      title: "Curriculums",
      dataTesting: "sidebar-curriculums",
      isHighlighted: isCurrentRouteIncluded(views.curriculums),
      isNotAvailable: !orgStore.canOrgManageCurriculums,
      to: {
        name: "curriculums"
      },
      icon: CurriculumIcon
    },
    {
      title: "Attachments",
      dataTesting: "sidebar-files",
      isHighlighted: isCurrentRouteIncluded(views.files),
      isNotAvailable: !orgStore.canOrgManageProgrammes,
      to: {
        name: "files"
      },
      icon: FilesIcon
    },
    {
      title: "Users",
      dataTesting: "sidebar-manage-permissions",
      isHighlighted: isCurrentRouteIncluded(views.users),
      to: {
        name: "users"
      },
      icon: TeamIcon
    }
  ];
});
</script>

<style scoped lang="scss">
.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 901; // Must be greater than the z-index of the header
  height: 100vh;
  width: $sidebar-width-sm;
  background-color: $primary-color;
  color: white;
  transition: width 0.3s ease-out;

  &.open {
    width: $sidebar-width;
    @media (max-width: $breakpoint-md) {
      width: $sidebar-width-md;
    }
  }
}
.sidebar-inner {
  width: $sidebar-width;
  @media (max-width: $breakpoint-md) {
    width: $sidebar-width-md;
  }
}
.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid $blue100;
}
.sidebar-logo {
  font-weight: 800;
  font-size: 20px;
  color: $blue100;
}
.btn-toggle {
  transition: color 0.2s ease-out;
  color: $blue20;
  &:hover,
  &:active,
  &:focus {
    color: $blue40;
  }
}
.icon-close {
  padding: 1rem 0;
  width: $sidebar-width-sm;
  display: flex;
  justify-content: center;
}
a {
  color: white;
  &:hover,
  &:focus {
    color: white;
    text-decoration: underline;
    outline: none;
  }
}
.primary-links {
  overflow: hidden;
  padding: 1rem 0;
}
hr {
  margin: 0;
  border: 1px solid $primary-color;
  border-bottom: 1px solid $primary-color-light;
}
</style>
