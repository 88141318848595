export const USER = {
  TYPES: [
    {
      id: 1,
      name: "External"
    },
    {
      id: 2,
      name: "Internal"
    }
  ],
  PERMISSIONS: {
    manageUsers: 7,
    manageProducts: 6,
    manageWorkflows: 3,
    manageContent: 9
  },
  FEATURES: {
    manageProgrammes: 1,
    manageCurriculums: 2
  },
  ERRORS: {
    passwordLength: "The password must contain at least 6 characters",
    passwordUpdate: "Password not updated",
    nameLength: "The username must contain at least 1 character",
    firstNameLength: "The first name must contain at least 1 character",
    lastNameLength: "The last name must contain at least 1 character",
    typeError: "Select the user type"
  }
};
