import * as adaptiveLearningSystems from "@/api/adaptive-learning-systems";
import * as assets from "@/api/assets";
import * as auth from "@/api/auth";
import * as batch from "@/api/batch";
import * as content from "@/api/content";
import * as contentTemplate from "@/api/content-template";
import * as curriculum from "@/api/curriculum";
import * as document from "@/api/document";
import * as file from "@/api/file";
import * as mcq from "@/api/mcq";
import * as metadata from "@/api/metadata";
import * as organization from "@/api/organization";
import * as permissions from "@/api/permissions";
import * as product from "@/api/product";
import * as program from "@/api/program";
import * as user from "@/api/user";
import * as qti from "@/api/qti";
import * as contentExtraction from "@/api/content-extraction";

const API = {
  adaptiveLearningSystems,
  assets,
  auth,
  batch,
  content,
  contentTemplate,
  curriculum,
  document,
  file,
  mcq,
  metadata,
  organization,
  permissions,
  product,
  program,
  qti,
  user,
  contentExtraction
};

export default API;
